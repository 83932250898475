document.addEventListener("DOMContentLoaded", function () {
	$(document).ready(function () {
		setup_tooltip();
		const path = window.location.pathname;
		if (path === "/trade-term-contract/list") {
			const listContainer = $(".web-list-container");
			if (listContainer.length > 0) {
				const tabs = ["Action Needed", "Pending", "Completed", "Expired", "Rejected"];
				const newItem = $("<div>", { class: "rebate-contract-filters" });
				createTabs(tabs, newItem, "#workflow_state");
				listContainer.children().eq(0).after(newItem);
			}
		}
		setTimeout(() => {
			if (path === "/trade-term-contract/list") {
				// Rearrange the filter fields based on the new order
				["supplier_name", "supplier_id", "trade_terms_type", "start_date", "end_date"].forEach(
					(fieldName) => {
						const $filter = $(`.web-list-filters .form-group[data-fieldname='${fieldName}']`);
						$(".web-list-filters").append($filter);
					}
				);
				addPlaceHolderToSelectField("trade_terms_type", "Trade Term Type");
				addPlaceHolderToSelectField("vendor_contract_status", "Vendor Contract Status");
				$(".rebate-contract-filters").find("button:first").trigger("click");
			}
		}, 500);
	});
});

function setup_tooltip() {
	setTimeout(() => {
		// Hide the "Sr." column header
		$('th:contains("Sr.")').hide();

		// Hide the cells in the "Sr." column
		$("td.list-col-serial").hide();
		// Select all table rows within the web-list-table class
		var rows = document.querySelectorAll(".web-list-table tbody tr");

		if($(".web-list-table thead tr").find(".th_action").length<=0){
			$(".web-list-table thead tr").append('<th class="th_action"></th>');
		}		

		rows.forEach(function (row) {
			// Get the row ID
			var rowId = row.id;

			let link_pdf = `/printview?doctype=Trade Term Contract&name=${
					rowId
				}&format=${encodeURIComponent(
					"Direct or Distributor or Drop ship"
				)}&no_letterhead=0&letterhead=Chewy&settings=%7B%7D&_lang=en`;;

			if($(row).find(".td_action").length<=0){
				$(row).append(`<td class="td_action"><a href="${link_pdf}" target="_blank" class="btn btn-action btn-default btn-xs" title="Show PDF ${rowId}">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path d="M8 2c1.981 0 3.671.992 4.933 2.078 1.27 1.091 2.187 2.345 2.637 3.023a1.62 1.62 0 0 1 0 1.798c-.45.678-1.367 1.932-2.637 3.023C11.67 13.008 9.981 14 8 14c-1.981 0-3.671-.992-4.933-2.078C1.797 10.83.88 9.576.43 8.898a1.62 1.62 0 0 1 0-1.798c.45-.677 1.367-1.931 2.637-3.022C4.33 2.992 6.019 2 8 2ZM1.679 7.932a.12.12 0 0 0 0 .136c.411.622 1.241 1.75 2.366 2.717C5.176 11.758 6.527 12.5 8 12.5c1.473 0 2.825-.742 3.955-1.715 1.124-.967 1.954-2.096 2.366-2.717a.12.12 0 0 0 0-.136c-.412-.621-1.242-1.75-2.366-2.717C10.824 4.242 9.473 3.5 8 3.5c-1.473 0-2.825.742-3.955 1.715-1.124.967-1.954 2.096-2.366 2.717ZM8 10a2 2 0 1 1-.001-3.999A2 2 0 0 1 8 10Z"></path></svg>
				</a></td>`);
			}

			// Select all ellipsis elements within the current row
			var ellipsisElements = row.querySelectorAll(".ellipsis");

			// Add title attribute to each ellipsis element with the row ID
			ellipsisElements.forEach(function (element) {
				element.setAttribute("title", rowId);
			});
		});
		// onlcick it renders more records so to add tooltip to that records aswell  adding this listener to Load More button
		$(".more").click(() => {
			setup_tooltip();
		});
	}, 1000);
}

function createTabs(tabs, container, targetField) {
	const buttons = tabs.map((text) => $("<button>").text(text));
	container.html(buttons);

	container.on("click", "button", function () {
		setup_tooltip();
		const tabText = $(this).text().toLowerCase().replace(" ", "_");
		$(this).addClass("selected").siblings().removeClass("selected");
		$(targetField).val(tabText);
		const dropdown = $(`[data-fieldname="${targetField.substring(1)}"] select`);
		dropdown.val(tabText).trigger("change");
	});

	$(document).on("change", `[data-fieldname="${targetField.substring(1)}"] select`, function () {
		const selectedValue = $(this).val();
		const tab = container.find("button").filter(function () {
			return $(this).text().toLowerCase().replace(" ", "_") === selectedValue;
		});
		tab.addClass("selected").siblings().removeClass("selected");
	});
}

function addPlaceHolderToSelectField(fieldName, placeholderText) {
	let $selectField = $(`select[data-fieldname="${fieldName}"]`);
	if ($selectField.length) {
		$selectField.prepend(`<option value="" disabled selected>${placeholderText}</option>`);
	}
}
